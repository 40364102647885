.favorite {
  position: absolute;
  left: auto;
  right: 0;
  top: 0;
  bottom: auto;
  background: none;
  border: 0;
  padding: 1%;
}

.down-image {
  position: absolute;
  transition: opacity .5s;
  opacity: 0;
}

.down-image:hover {
  background-color: white;
  position: absolute;
  transition-delay:.5s;
  opacity: 1;
}

.upper-image {
  position: absolute;
  transition: opacity .5s;
  opacity: 0;
}

.upper-image:hover {
  position: absolute;
  transition-delay:.5s;
  opacity: 1;
}