@keyframes stripe-slide {
    0% {
      background-position: 0% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  .btn {
    overflow: visible;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    font: inherit;
    line-height: normal;
    cursor: pointer;
    -moz-user-select: text;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 1vw 1vh;
    background-color: #fff;
    color: #666;
    border: 2px solid #666;
    border-radius: 6px;
    transition: all 0.5s ease;
  }
  .btn:-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  .btn--stripe {
    overflow: hidden;
    position: relative;
  }
  .btn--stripe:after {
    content: '';
    display: block;
    height: 7px;
    width: 100%;
    background-image: repeating-linear-gradient(45deg, #666, #666 1px, transparent 2px, transparent 5px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-top: 1px solid #666;
    position: absolute;
    left: 0;
    bottom: 0;
    background-size: 7px 7px;
  }
  .btn--stripe:hover {
    background-color: #666;
    color: #fff;
    border-color: #000;
  }
  .btn--stripe:hover:after {
    background-image: repeating-linear-gradient(45deg, #fff, #fff 1px, transparent 2px, transparent 5px);
    border-top: 1px solid #000;
    animation: stripe-slide 12s infinite linear forwards;
  }
  .btn-animation:hover {
    border-top: 2px solid #000;
    background-color: #666;
    color: #fff;
    border-color: #000;
  }
  .btn--radius {
    border-radius: 36px;
  }

  .developer {
    margin: 0 auto;
  }

  .developer > img {
    object-fit: contain;
    width: 65%;
    height: 65%;
  }

  .developer > div > a > svg {
    height: 2.5em;
    width: 2.5em;
    color: black;
  }