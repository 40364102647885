* {
  overflow: hidden;
}

.svrradio {
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  font-size: 2.5vw;
  font-family: "CrosshatcherD";
  display: flex;
  align-items: center;
  /* position: absolute;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto; */
}

.SVR_Radio {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 1%;
  max-width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/*#region TopBar*/
.topbar {
  padding: 0.5%;
  border: 2px solid #666;
  border-radius: 0.25rem;
  order: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
}

.leftposition {
  display: flex;
  margin-right: auto;
  margin-left: 1%;
}

.Donate a+a {
  margin-top: 5%;
}

.Donate a img {
  width: 10vw;
  object-fit: scale-down;
  height: 3vh;
}

.rightposition {
  display: flex;
  margin-left: auto;
  margin-right: 1%;
}

/*#endregion*/

/*#region Main panel*/
.mainpanel {
  border-radius: 0.25rem;
  display: flex;
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

/*#region Radio list panel*/
.rafiolistpanel {
  padding: 5px;
  border: 2px solid #666;
  display: flex;
  flex-direction: column;
  order: 0;
  border-radius: 0.25rem;
  width: 25vw;
  margin-top: 1%;
  margin-right: 0.5%;
}

.radiolist {
  padding: 1%;
  order: 0;
  border-radius: 0.25rem;
  overflow: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 2vmin;
  border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb {
  border-top: 2px solid #666;
  border-bottom: 2px solid #666;
  border-left: 2px solid #666;
  border-right: 2px solid #666;
  border-radius: 1.25rem;
}

::-webkit-scrollbar-thumb:hover {
  border-top: 2px solid #666;
  border-bottom: 2px solid #666;
  border-left: 2px solid #666;
  border-right: 2px solid #666;
  border-radius: 1.25rem;
  background-color: rgba(102, 102, 102, 1);
}

.buttonpanel {
  align-self: center;
  display: flex;
  align-items: center;
}

.volume-slider {
  -webkit-appearance: none;
  border-radius: 1.25rem;
  border: 2px solid #666;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  width: 50%;
  margin-right: 6%;
}

.volume-slider::-webkit-slider-thumb:hover {
  background-color: #666;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 1.25rem;
  border: 2px solid #666;
  cursor: pointer;
}

.volume-slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04AA6D;
  cursor: pointer;
}

.nav.nav-pills .nav-link.active {
  background-color: #666
}

.radiocard {
  box-shadow: none;
  margin: 1%;
  padding: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.currentWeek {
  position: absolute;
  left: 0;
  right: 0.5vw;
  top: auto;
  bottom: 0;
  text-align: right;
  font-size: 1vh;
}

/*#endregion*/

/*#region Radio panel*/
.radiopanel {
  width: -webkit-fill-available;
  border: 2px solid #666;
  border-radius: 0.25rem;
  display: flex;
  order: 0;
  margin-top: 1%;
  flex-direction: column;
  margin-left: 0.5%;
}

.infopanel-text {
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  text-align: center;
  justify-content: center;
}

.infopanel-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.musicVisual {
  transform: translate(9vh, -13vh);
  position: absolute;
  right: 0;
  bottom: 0;
}

.mediabuttonpanel {
  border-top: 2px solid #666;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
}

.Player {
  background: transparent;
  border: 0px solid #666;
  transform: scale(0.3);
}

.Rewind {
  background: transparent;
  border: 0px solid #666;
  transform: scale(0.3);
}

/*#endregion*/

/*#endregion*/