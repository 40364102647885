@keyframes stripe-slide {
    0% {
      background-position: 0% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }

  .btn-img-country {
    position: absolute;
    top: 0.5vh;
    bottom: auto;
    left: auto;
    right: 0.5vw;
    height: 1.5vh;
    width: 1.5vw;
  }

  .btn-list {
    box-shadow: none !important;
    margin: 1% !important;
    padding: 10% !important;
    display: flex !important;
    align-items: center !important;
    place-content: center space-evenly !important;
    flex-flow: row !important;
    flex: 1 1 0% !important;
  }

  .btn {
    overflow: visible;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    font: inherit;
    line-height: normal;
    cursor: pointer;
    -moz-user-select: text;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    padding: 1vh 2vw;
    background-color: #fff;
    color: #666;
    border: 2px solid #666;
    border-radius: 6px;
    transition: all 0.5s ease;
    text-align: center;
  }
  .btn:-moz-focus-inner {
    padding: 0;
    border: 0;
  }
  .btn--stripe {
    overflow: hidden;
    position: relative;
  }
  .btn--stripe:after {
    content: '';
    display: block;
    height: 7px;
    width: 100%;
    background-image: repeating-linear-gradient(45deg, #666, #666 1px, transparent 2px, transparent 5px);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-top: 1px solid #666;
    position: absolute;
    left: 0;
    bottom: 0;
    background-size: 7px 7px;
  }
  .btn--stripe:focus {
    background-color: #666;
    color: #fff;
    border-color: #000;
  }
  .btn--stripe:hover:after {
    background-image: repeating-linear-gradient(45deg, #fff, #fff 1px, transparent 2px, transparent 5px);
    border-top: 1px solid #000;
    animation: stripe-slide 12s infinite linear forwards;
  }
  .btn-animation:hover {
    border-top: 1px solid #000;
    background-color: #666;
    color: #fff;
    border-color: #000;
  }
  .btn--radius {
    border-radius: 36px;
  }
  